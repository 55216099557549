<template>
  <el-card>

    <el-form
        ref="addAdRef"
        :rules="createAdRules"
        :model="options"
        label-width="80px">
      <!--<el-form-item :label="$t('lang')">-->
      <!--  <el-radio v-model="lang" label="zh-CN">中文</el-radio>-->
      <!--  <el-radio v-model="lang" label="en-US">English</el-radio>-->
      <!--</el-form-item>-->

      <el-form-item :label="$t('page.console.ad_settings.posts')">
        <el-table :data="options.posts" border>
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="image">
            <template slot-scope="scope">
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover">
                <el-image :src="scope.row.image"></el-image>
                <el-input slot="reference" v-model="scope.row.image" size="small" clearable>
                  <el-button slot="prepend" icon="el-icon-upload" @click="selectImages('posts', scope.$index)"></el-button>
                </el-input>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column>
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small">
                <el-button
                    slot="append"
                    @click="selectView('posts', scope.$index)"
                    type="primary"
                    size="mini"
                    icon="el-icon-zoom-in">
                </el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('posts', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-button type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('posts')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <el-form-item>
        <el-button class="right mt20" size="mini" type="primary" @click="onSave">{{$t('button.save')}}</el-button>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="dialogVisible" :title="$t('tips.select')">
      <select-image
          :multiple="multiple"
          @select="getImages"
          @close="dialogVisible = false">
      </select-image>
    </el-dialog>

    <el-dialog :visible.sync="selectPostDialogVisible" :title="$t('tips.select')">
      <select-post @select="select"></select-post>
    </el-dialog>
  </el-card>
</template>

<script>
import selectImage from '@/components/Images';
import selectPost from '@/view/os/console/SelectPost'
import {getOption, updateOption, getPosts, savePosts} from '@/api/os/admin';


export default {
  data() {
    return {
      optionName: 'page-ad',
      dialogVisible: false,
      selectType: 'posts',
      multiple: false,
      options: {
        posts: []
      },
      lang: 'zh-CN',
      selectPostDialogVisible: false,
      selectMeta: {
        name: '',
        index: 0
      }
    }
  },
  created() {
    this.getOptions();
  },
  computed: {
    // add
    createAdRules() {

    }
  },
  components: {
    selectImage,
    selectPost
  },
  methods: {
    getOptions() {
      getOption({
        name: this.optionName,
      }).then(res => {
        if(res.data.data) {
          this.options = res.data.data
        }
      })
    },
    remove(type, index) {
      this.options[type].splice(index, 1)
    },
    addAttrs(name) {
      this.options[name].push({
        image: '',
        url: ''
      })
    },
    selectImages(type, index) {
      this.dialogVisible = true
      this.index = index
      this.selectType = type
    },
    getImages(data) {
      this.dialogVisible = false;
      this.$set(this.options[this.selectType][this.index], 'image', data)
    },
    selectView(name, index) {
      this.selectMeta.name = name
      this.selectMeta.index = index
      this.selectPostDialogVisible = true
    },
    select(data) {
      let url = data.url
      this.$set(this.options[this.selectMeta.name][this.selectMeta.index], 'url', url);
      this.selectPostDialogVisible = false
    },
    onSave() {
      updateOption({
        name: this.optionName,
        value: this.options
      }).then(res => {
        this.$message.success(this.$t('tips.success'))
      })
    }
  }
}
</script>